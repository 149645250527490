<mat-drawer-container>
  <mat-drawer style="position: fixed; border-radius: 0" class="side-menu" [mode]="mobile ? 'over' : 'side'"
    [opened]="menuVisible || !mobile" (closed)="hideMenu()">
    <a [href]="'/'" class="block" (mousedown)="hideMenu()">
      <img [src]="logo" class="block mx-auto my-3" alt="instance logo" style="max-width: 200px; max-height: 72px" />
    </a>
    <hr class="mx-3" />
    <mat-nav-list>
      @for (link of menuItems; track $index) { @if (link.divider) {
      <hr style="margin: 16px 0" />
      } @else {
      <app-menu-item [item]="link"></app-menu-item>
      } }
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <button style="position: fixed; z-index: 500; top: 1em; left: 0.5em" mat-flat-button color="primary"
      *ngIf="!menuVisible" [hidden]="!mobile" type="button" class="show-sidebar-menu-button affected-color-buttons"
      (mousedown)="showMenu()"
      [matBadge]="notifications + adminNotifications + usersAwaitingAproval + followsAwaitingAproval + awaitingAsks"
      [matBadgeHidden]="
        notifications + adminNotifications + usersAwaitingAproval + followsAwaitingAproval + awaitingAsks === 0
      " matBadgePosition="below">
      <fa-icon [icon]="hamburguerIcon"></fa-icon>
    </button>
    <button style="position: fixed; z-index: 500; bottom: 1em; right: 0.5em" mat-flat-button color="primary"
      [hidden]="!mobile || !jwtService.tokenValid() || currentRoute == '/editor'" type="button"
      class="show-sidebar-menu-button affected-color-buttons" (mousedown)="this.hideMenu(); this.openEditor()">
      <fa-icon [icon]="pencilIcon"></fa-icon>
    </button>
    <div class="pt-7">
      <div *ngIf="maintenanceMode" [innerHtml]="maintenanceMessage">
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>